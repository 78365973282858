import InfoOutlinedcon from '@mui/icons-material/InfoOutlined';
import { Alert, Box, Checkbox, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CouponsBenefitOptionsDTOAlternateBenefitsEnum,
  CreateBenefitActivationDTO,
  FitnessBenefitOptionsDTOFitnessBudgetEnum,
  UpdateBenefitActivationDTO,
  UpdateCouponsBenefitOptionsDTO,
} from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SelectControl } from '../../../component/form';
import { fillNumberRangeArray } from '../../../util/numberRange';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { NonCashLimitWarning } from '../ActivationDialog/NonCashLimitWarning';
import { AvailableCouponsAccordion } from './AvailableCouponsDrawer';

type BenefitOptions = UpdateCouponsBenefitOptionsDTO & { disabled?: boolean };
// TODO this value should be configurable per tax period (TaxConfig)
const MIN = 500;
const MAX = 5000;
const BUDGET_OPTIONS = fillNumberRangeArray(MIN, MAX, 500);
BUDGET_OPTIONS.push(4400);
BUDGET_OPTIONS.sort((a, b) => a - b);

const Header: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const { watch } = useFormContext();
  const { employeeId } = useParams();

  const benefitOptions: BenefitOptions = watch('benefitOptions');
  const editMode = watch('editMode');

  const isAfterDeadline = tenant?.couponOrderDeadline && DateTime.now().day > tenant.couponOrderDeadline;

  const willExceedBudget = DateTime.now().day > 25 && (tenant?.couponBudget || 0) < benefitOptions.monthlyBudget;

  return (
    <>
      {isAfterDeadline && !editMode ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {t('COUPONS.deadlineActivationWarning')}
        </Alert>
      ) : null}
      <NonCashLimitWarning employeeId={employeeId} benefit={BenefitDTOBenefitEnum.Coupons} monthlyBudget={benefitOptions.monthlyBudget} />
      {willExceedBudget && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          {t('budgetExceededWarning')}
        </Alert>
      )}
    </>
  );
};

const Fields: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const { tenant } = useTenant();
  const { t } = useTranslation('benefitsModule');

  const { data, isLoading } = useTenantQuery(['benefits', 'coupons', 'definition', apis.coupons.findCouponDefinitions.name], tenantId =>
    apis.coupons.findCouponDefinitions({ tenantId, sortBy: ['name:asc'] }).then(resp => resp.data),
  );

  const benefitOptions: BenefitOptions = watch('benefitOptions');

  const [isFitnessGranted, setIsFitnessGranted] = useState(!!benefitOptions.alternateBenefits?.length);

  const hasTenantFitness = tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Fitness);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement> | undefined, checked: boolean): void => {
    setIsFitnessGranted(checked);
  }, []);

  const { alternateBenefits, disabled } = useMemo(() => {
    const isFitnessAmount = Object.values(FitnessBenefitOptionsDTOFitnessBudgetEnum).some(
      amount => amount === benefitOptions.monthlyBudget,
    );
    const enabled = hasTenantFitness && isFitnessAmount;
    const alternateBenefits = enabled && isFitnessGranted ? [CouponsBenefitOptionsDTOAlternateBenefitsEnum.Fitness] : [];

    return { alternateBenefits, disabled: !enabled };
  }, [benefitOptions.monthlyBudget, hasTenantFitness, isFitnessGranted]);

  useEffect(() => {
    setValue('benefitOptions.alternateBenefits', alternateBenefits);
    handleChange(undefined, !!alternateBenefits.length);
  }, [alternateBenefits, handleChange, setValue]);

  return (
    <>
      {!isLoading && benefitOptions.monthlyBudget && (
        <Box mb={2}>
          <AvailableCouponsAccordion coupons={data?.coupons} amount={benefitOptions.monthlyBudget} />
        </Box>
      )}
      {hasTenantFitness && (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isFitnessGranted}
            disabled={benefitOptions.disabled || (!isFitnessGranted && disabled)}
            onChange={handleChange}
          />
          <Typography>{t('FITNESS.grantBenefit')}</Typography>

          <Tooltip title={t('FITNESS.amountHint')}>
            <InfoOutlinedcon color="action" fontSize="small" sx={{ ml: 0.5 }}></InfoOutlinedcon>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

const InlineField: React.FC = () => {
  const { t, i18n } = useTranslation('benefitsModule');
  const { control, watch } = useFormContext();

  const benefitOptions: BenefitOptions = watch('benefitOptions');
  return (
    <SelectControl
      control={control}
      name="benefitOptions.monthlyBudget"
      label={t('COUPONS.fields.monthlyBudget')}
      data-test-id="activate-dialog-monthly-budget"
      fullWidth
      margin="dense"
      rules={{ required: true }}
      disabled={benefitOptions.disabled}
      options={BUDGET_OPTIONS.map(n => ({ value: n, label: i18n.format(n, 'euro-float') }))}
    />
  );
};

export function useCouponsDialogExtensions(): ActivationDialogExtensions<BenefitOptions> {
  const handleResetBenefitOptions = useCallback((selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): BenefitOptions => {
    return {
      monthlyBudget: selectedActivation?.couponsOptions
        ? selectedActivation.couponsOptions.monthlyBudget
        : BUDGET_OPTIONS[BUDGET_OPTIONS.length - 1],
      alternateBenefits: selectedActivation?.couponsOptions?.alternateBenefits ?? [],
      disabled: !!selectedActivation?.couponsOptions?.alternateBenefits?.length,
    };
  }, []);

  const mapCouponsOptions = useCallback(
    (benefitOptions: BenefitOptions): UpdateCouponsBenefitOptionsDTO => ({
      monthlyBudget: benefitOptions.monthlyBudget,
      alternateBenefits: benefitOptions.alternateBenefits,
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<BenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Coupons,
        couponsOptions: mapCouponsOptions(benefitOptions),
      };
      await saveActivation(create);
    },
    [mapCouponsOptions],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<BenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        couponsOptions: mapCouponsOptions(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapCouponsOptions],
  );

  return useMemo(
    () => ({
      Header,
      Fields,
      InlineField,
      earliestStartDate: DateTime.now().plus({ months: DateTime.now().day > 25 ? 1 : 0 }),
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
