import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { MRT_ColumnDef } from 'material-react-table';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  EmployeeDTO,
  MobilityBenefitOptionsDTOTaxationTypeEnum,
} from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortByDef } from '../../component/table/sortMapper';

const DEFAULT_MAPPER = (value: unknown): BenefitActivationDTO | BenefitTemplateItemDTO =>
  value as BenefitActivationDTO | BenefitTemplateItemDTO;

export interface BenefitAttribute<T> {
  id: string;
  accessorFn: (value: T) => unknown;
  header: string;
  size?: number;
  maxSize?: number;
  renderer?: (value: unknown) => React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBenefitAttributes<T extends Record<string, any> = BenefitActivationDTO | BenefitTemplateItemDTO>(
  benefit: BenefitDTOBenefitEnum,
  mapper: (value: T) => BenefitActivationDTO | BenefitTemplateItemDTO | undefined = DEFAULT_MAPPER,
): BenefitAttribute<T>[] {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();

  const couponsColumns = useMemo(() => {
    const baseColumns: BenefitAttribute<T>[] = [
      {
        id: 'couponsMonthlyBudget',
        accessorFn: value => mapper(value)?.couponsOptions?.monthlyBudget,
        header: t('COUPONS.fields.monthlyBudget'),
        renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
      },
    ];

    if (tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Fitness)) {
      baseColumns.push({
        id: 'isFitnessActive',
        accessorFn: value => mapper(value)?.couponsOptions?.alternateBenefits?.includes(BenefitDTOBenefitEnum.Fitness),
        header: t('employeeTabs.header.FITNESS'),
        renderer: value => (value ? <CheckIcon color="success" /> : <CloseIcon color="error" />),
      });
    }
    return baseColumns;
  }, [mapper, t, tenant?.activeBenefits]);

  return useMemo(() => {
    const attributes: Record<BenefitDTOBenefitEnum, BenefitAttribute<T>[]> = {
      [BenefitDTOBenefitEnum.Gifts]: [],
      [BenefitDTOBenefitEnum.Bikeleasing]: [],
      [BenefitDTOBenefitEnum.Lunch]: [
        {
          id: 'lunchMaxNumberOfReceipts',
          accessorFn: value => mapper(value)?.lunchOptions?.maxNumberOfReceipts,
          header: t('LUNCH.fields.mealsPerMonth'),
        },
        {
          id: 'lunchDailyLimit',
          accessorFn: value => mapper(value)?.lunchOptions?.dailyLimit,
          header: t('LUNCH.fields.perMeal'),
          size: 100,
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
        {
          id: 'lunchMaxAmount',
          maxSize: 120,
          accessorFn: value =>
            mapper(value)?.lunchOptions && mapper(value)!.lunchOptions!.maxNumberOfReceipts * mapper(value)!.lunchOptions!.dailyLimit,
          header: t('LUNCH.fields.maxAmount'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
      ],
      [BenefitDTOBenefitEnum.Mobility]: [
        {
          id: 'mobilityMonthlyLimit',
          accessorFn: value => mapper(value)?.mobilityOptions?.monthlyLimit,
          header: t('MOBILITY.fields.monthlyLimit'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
        {
          id: 'mobilityTaxationType',
          accessorFn: value => mapper(value)?.mobilityOptions?.taxationType,
          header: t('MOBILITY.fields.taxationTypeColumn'),
          renderer: value =>
            value === MobilityBenefitOptionsDTOTaxationTypeEnum.Taxable ? (
              <CheckIcon color="success" />
            ) : value === MobilityBenefitOptionsDTOTaxationTypeEnum.TaxFree ? (
              <CloseIcon color="error" />
            ) : null,
        },
      ],
      [BenefitDTOBenefitEnum.Internet]: [
        {
          id: 'internetMonthlyBudget',
          accessorFn: value => mapper(value)?.internetOptions?.monthlyBudget,
          header: t('INTERNET.fields.monthlyBudget'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
      ],
      [BenefitDTOBenefitEnum.Coupons]: couponsColumns,
      [BenefitDTOBenefitEnum.CouponsV2]: [
        {
          id: 'couponsV2MonthlyBudget',
          accessorFn: value => mapper(value)?.couponsV2Options?.monthlyBudget,
          header: t('COUPONS_V2.fields.monthlyBudget'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
      ],
      [BenefitDTOBenefitEnum.Bav]: [
        {
          id: 'bavAdditionalPaymentPercent',
          accessorFn: value => mapper(value)?.bavOptions?.additionalPaymentPercent,
          header: t('BAV.fields.additionalPaymentPercent'),
          renderer: value => (typeof value === 'number' ? `${value || 0}%` : null),
        },

        {
          id: 'bavVWLConversion',
          accessorFn: value => mapper(value)?.bavOptions?.vwlConversion,
          header: t('BAV.fields.vwlConversion'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
      ],
      [BenefitDTOBenefitEnum.Bike]: [
        {
          id: 'bikePlatformName',
          accessorFn: value => mapper(value)?.bikeOptions?.platformName || t('BIKE.otherProvider'),
          header: t('BIKE.fields.platformName'),
        },
        {
          id: 'bikeEmployerGrant',
          accessorFn: value => mapper(value)?.bikeOptions?.employerGrant,
          header: t('BIKE.fields.employerGrant'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
      ],
      [BenefitDTOBenefitEnum.Custom]: [
        {
          id: 'customMonthlyGrant',
          accessorFn: value => mapper(value)?.customBenefitOptions?.monthlyGrant,
          header: t('CUSTOM.fields.monthlyGrant'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
        {
          id: 'customSupplier',
          accessorFn: value => mapper(value)?.customBenefitOptions?.supplier,
          header: t('CUSTOM.fields.supplier'),
          renderer: value =>
            typeof value === 'string' ? <div style={{ maxWidth: 150, overflowX: 'hidden', textOverflow: 'ellipsis' }}>{value}</div> : null,
        },
        {
          id: 'customComment',
          accessorFn: value => mapper(value)?.customBenefitOptions?.comment,
          header: t('CUSTOM.fields.comment'),
          renderer: value =>
            typeof value === 'string' ? <div style={{ maxWidth: 150, overflowX: 'hidden', textOverflow: 'ellipsis' }}>{value}</div> : null,
        },
      ],
      [BenefitDTOBenefitEnum.News]: [],
      [BenefitDTOBenefitEnum.Flex]: [
        {
          id: 'flexMonthlyBudget',
          accessorFn: value => mapper(value)?.flexOptions?.monthlyBudget,
          header: t('FLEX.fields.monthlyBudget'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : null),
        },
        ...(tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Mobility)
          ? ([
              {
                id: 'flexMobilityTaxationType',
                accessorFn: value => mapper(value)?.flexOptions?.mobilityTaxationType,
                header: t('MOBILITY.fields.taxationTypeColumn'),
                renderer: value =>
                  value === MobilityBenefitOptionsDTOTaxationTypeEnum.Taxable ? (
                    <CheckIcon color="success" />
                  ) : value === MobilityBenefitOptionsDTOTaxationTypeEnum.TaxFree ? (
                    <CloseIcon color="error" />
                  ) : null,
              },
            ] satisfies BenefitAttribute<T>[])
          : []),
      ],
      [BenefitDTOBenefitEnum.Recreation]: [
        {
          id: 'recreationYearlyBudget',
          accessorFn: value => mapper(value)?.recreationOptions?.yearlyBudget,
          header: t('RECREATION.fields.yearlyBudget'),
          renderer: value => (typeof value === 'number' ? t('common:money', { money: value }) : t('RECREATION.individualBudget')),
        },
      ],
      [BenefitDTOBenefitEnum.Fitness]: [],
    };
    return attributes[benefit] || [];
  }, [t, couponsColumns, tenant?.flexBenefits, benefit, mapper]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBenefitColumns<T extends Record<string, any> = BenefitActivationDTO | BenefitTemplateItemDTO>(
  benefit: BenefitDTOBenefitEnum,
  mapper: (value: T) => BenefitActivationDTO | BenefitTemplateItemDTO | undefined = DEFAULT_MAPPER,
): (MRT_ColumnDef<T> & SortByDef)[] {
  const attributes = useBenefitAttributes(benefit, mapper);

  return useMemo(
    (): (MRT_ColumnDef<T> & SortByDef)[] =>
      attributes.map(attribute => ({
        id: attribute.id,
        accessorFn: attribute.accessorFn,
        header: attribute.header,
        size: attribute.size,
        maxSize: attribute.maxSize,
        enableSorting: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: attribute.renderer && (({ cell }) => attribute.renderer!(cell.getValue() as any)),
      })),
    [attributes],
  );
}

const getBenefitActivationFromEmployee = (employee: EmployeeDTO): BenefitActivationDTO | undefined => employee.benefitActivation;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useEmployeeBenefitColumns = (benefit: BenefitDTOBenefitEnum) => useBenefitColumns(benefit, getBenefitActivationFromEmployee);
